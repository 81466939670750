import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ProductV1 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL+'/'

		return    <section className="all-item-area pd-top-90 pd-bottom-100" id="produkty">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className="section-title">
							<h2>Produkty o Gran Canarii</h2>
						</div>
					</div>
					<div className="col-lg-6 mt-2">
						<div className="isotope-filters item-isotope-btn text-lg-right">
							{/*<button className="button active ml-0" data-filter="*">Wszystko</button>*/}
							<button className="button active ml-0" data-filter=".cat-1">E-booki</button>
							<button className="button" data-filter=".cat-3">Pakiety</button>
							<button className="button" data-filter=".cat-2">Konsultacje</button>
						</div>
					</div>
				</div>
				<div className="all-item-section">
					<div className="item-isotope row">
						<div className="item-sizer col-1" />
						{/* gallery item start here */}
            <div className="all-isotope-item col-lg-4 col-sm-6 cat-1">
              <a href="https://takeyourway.pl/produkt/planownik-poznaj-gran-canarie/?utm_source=landing_page_poznaj_gran_canarie&utm_medium=button_click&utm_campaign=common_sale&utm_content=product_button">
                <div className="thumb">
                  <img src={publicUrl + "assets/img/product/gran-canaria-planownik-1080.jpg"} alt="image"/>
                </div>
              </a>
              <div className="item-details">
                <span className="price">79 PLN</span>
                <span className="ratting float-right">
                  <i className="fas fa-hiking highlight"></i>
                </span>
                <h4><a
                  href="https://takeyourway.pl/produkt/planownik-poznaj-gran-canarie/?utm_source=landing_page_poznaj_gran_canarie&utm_medium=button_click&utm_campaign=common_sale&utm_content=product_name">
                  PLANOWNIK
                </a></h4>
                <p>Gotowe plany podróży po wyspie</p>
              </div>
            </div>
            <div className="all-isotope-item col-lg-4 col-sm-6 cat-1">
              <a href="https://takeyourway.pl/produkt/przewodnik-po-plazach-i-basenach-naturalnych-gran-canarii-ebook/?utm_source=landing_page_poznaj_gran_canarie&utm_medium=button_click&utm_campaign=common_sale&utm_content=product_button">
                <div className="thumb">
                  <img src={publicUrl + "assets/img/product/gran-canaria-plaze-i-baseny-naturalne-1080.jpg"} alt="image"/>
                </div>
              </a>
              <div className="item-details">
                <span className="price">79 PLN</span>
                <span className="ratting float-right">
                <i className="fas fa-umbrella-beach highlight"></i>
              </span>
                <h4><a
                  href="https://takeyourway.pl/produkt/przewodnik-po-plazach-i-basenach-naturalnych-gran-canarii-ebook/?utm_source=landing_page_poznaj_gran_canarie&utm_medium=button_click&utm_campaign=common_sale&utm_content=product_name">
                  PLAŻOWNIK
                </a></h4>
                <p>Encyklopedia plaż i basenów naturalnych</p>
              </div>
            </div>
            <div className="all-isotope-item all-isotope-item-disabled col-lg-4 col-sm-6 cat-1">
              <div className="thumb">
                <a className="gallery-fancybox" href="#">
                  <img src={publicUrl + "assets/img/product/gran-canaria-przewodnik-placeholder.jpg"} style={{ opacity: "0.5" }} alt="image" />
								</a>
								{/*<a className="btn btn-white" href="#/?utm_source=landing_page_poznaj_gran_canarie&utm_medium=button_click&utm_campaign=common_sale&utm_content=product_button">Zobacz</a>*/}
							</div>
							<div className="item-details">
								<span className="price-unavailable">NIEZNANA</span>
								<span className="ratting float-right">
									<i className="fas fa-question star-o"></i>
								</span>
								<h4>
									{/*<a href="https://takeyourway.pl/?utm_source=landing_page_poznaj_gran_canarie&utm_medium=button_click&utm_campaign=common_sale&utm_content=product_button">*/}
									PRZEWODNIK
									{/*</a>*/}
								</h4>
								<p>Wielki przewodnik po Gran Canarii</p>
							</div>
						</div>
            <div className="all-isotope-item col-lg-4 col-sm-6 cat-3">
              <a href="https://takeyourway.pl/produkt/zestaw-planownik-i-plazownik-poznaj-gran-canarie/?utm_source=landing_page_poznaj_gran_canarie&utm_medium=button_click&utm_campaign=common_sale&utm_content=product_button">
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/product/poznaj-gran-canarie-planownik-plazownik-1080.jpg"}
                    alt="image"
                  />
                </div>
              </a>
              <div className="item-details">
                <span className="price">129 PLN</span>
                <span className="ratting float-right">
                <i className="fas fa-umbrella-beach highlight"></i>
                <span style={{color: "rgb(72, 140, 0)"}}>+</span>
                <i className="fas fa-hiking highlight"></i>
              </span>
                <h4><a
                  href="https://takeyourway.pl/produkt/zestaw-planownik-i-plazownik-poznaj-gran-canarie/?utm_source=landing_page_poznaj_gran_canarie&utm_medium=button_click&utm_campaign=common_sale&utm_content=product_name">
                  PLANOWNIK + PLAŻOWNIK
                </a></h4>
                <p>Zestaw dwóch e-booków</p>
              </div>
            </div>
            <div className="all-isotope-item col-lg-4 col-sm-6 cat-2">
              <a
                href="https://takeyourway.pl/produkt/gran-canaria-szyta-na-miare/?utm_source=landing_page_poznaj_gran_canarie&utm_medium=button_click&utm_campaign=common_sale&utm_content=product_button">
                  <div className="thumb">
                    <img src={"https://takeyourway.pl/wp-content/uploads/2022/09/konsultacje-gran-canaria.jpg"} alt="image" />
                  </div>
                </a>
                <div className="item-details">
                  <span className="price">od 390 PLN</span>
                  <span className="ratting float-right">
									<i className="fas fa-headset highlight"></i>
								</span>
                  <h4>
                    <a
                      href="https://takeyourway.pl/produkt/gran-canaria-szyta-na-miare/?utm_source=landing_page_poznaj_gran_canarie&utm_medium=button_click&utm_campaign=common_sale&utm_content=product_name">
                      Gran Canaria szyta na miarę
                    </a>
                  </h4>
                  <p>Zaplanujemy Ci podróż po wyspie</p>
                </div>
            </div>
          </div>
        </div>
        {/*<div className="row">*/}
        {/*	<div className="col-lg-12 text-center">*/}
        {/*		<Link className="btn btn-base" to="/product">More Products</Link>*/}
        {/*	</div>*/}
        {/*</div>*/}
			</div>
		</section>

	}
}

export default ProductV1