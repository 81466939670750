import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class LatestNews extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    return (
     <section className="blog-area pd-top-90 pd-bottom-70 bg-sky-blue" id="artykuly">
		  <div className="container">
		    <div className="row justify-content-center">
		      <div className="col-lg-6">
		        <div className="section-title text-center">
		          <h2>Artykuły o Gran Canarii</h2>
		          <p>Oprócz produktów, które zbierają informacje w spójną całość, mamy dla Ciebie masę darmowej wiedzy o Gran Canarii. Przeczytaj artykuły, które pomogą Ci zaplanować podróż i odnaleźć się na miejscu.</p>
		        </div>
		      </div>
		    </div>
		    <div className="row justify-content-center">
					<div className="col-lg-4 col-md-6">
						<div className="single-blog-wrap">
							<div className="thumb">
								<img src={'https://takeyourway.pl/wp-content/uploads/2022/08/gran-canaria-pico-de-las-nieves-punkt-widokowy-768x432.jpg'} alt="img" />
							</div>
							<div className="blog-details">
								<div className="meta">
									<span><i className="far fa-calendar-alt" />26 SIERPNIA 2022</span>
									<span><i className="far fa-user" aria-hidden="true" />Takeyourway</span>
								</div>
								<h5>
									<a href="https://takeyourway.pl/gran-canaria-co-warto-wiedziec-przed-wyjazdem/">
										Gran Canaria – co warto wiedzieć przed wyjazdem?
									</a>
								</h5>
							</div>
						</div>
					</div>
		      <div className="col-lg-4 col-md-6">
		        <div className="single-blog-wrap">
		          <div className="thumb">
		            <img src={'https://takeyourway.pl/wp-content/uploads/2022/08/gran-canaria-galdar-768x432.jpg'} alt="img" />
		          </div>
		          <div className="blog-details">
		            <div className="meta">
		              <span><i className="far fa-calendar-alt" />29 SIERPNIA 2022</span>
		              <span><i className="far fa-user" aria-hidden="true" />Takeyourway</span>
		            </div>
		            <h5>
									<a href="https://takeyourway.pl/polnoc-czy-poludnie-gdzie-sie-zatrzymac-na-gran-canarii/">
										Północ czy południe? Gdzie się zatrzymać na Gran Canarii
									</a>
								</h5>
		          </div>
		        </div>
		      </div>
		      <div className="col-lg-4 col-md-6">
		        <div className="single-blog-wrap">
		          <div className="thumb">
		            <img src={'https://takeyourway.pl/wp-content/uploads/2022/08/gran-canaria-pogoda-2-768x432.jpg'} alt="img" />
		          </div>
		          <div className="blog-details">
		            <div className="meta">
		              <span><i className="far fa-calendar-alt" />24 SIERPNIA 2022</span>
		              <span><i className="far fa-user" aria-hidden="true" />Takeyourway</span>
		            </div>
		            <h5>
									<a href="https://takeyourway.pl/pogoda-na-gran-canarii/">
										Jaka jest pogoda na Gran Canarii?
									</a>
								</h5>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		  <div className="container">
			  <div className="row" style={{ marginTop: "20px" }}>
				  <div className="col-lg-12 text-center">
					  <a className="btn btn-base" href="https://takeyourway.pl/kategoria/kraje/europa/wyspy-kanaryjskie/gran-canaria/">Więcej artykułów</a>
				  </div>
			  </div>
			</div>
		</section>



    )
  }
}

export default LatestNews;
