import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import ProductV1 from './section-components/product-v1';
import Testimonial from './section-components/testimonial';
import LatestPost from './blog-components/latest-news';
import Footer from './global-components/footer';
import Newsletter from './section-components/newsletter';
import Socials from './section-components/socials';

const Home_V1 = () => {
  return <div>
    <Navbar />
    <Banner />
    <ProductV1 />
    <Newsletter />
    <LatestPost />
    <Socials />
    <Testimonial />
    <Footer />
    {/*<About />*/}
    {/*<Pricing />*/}
    {/*<Featured />*/}
  </div>
}

export default Home_V1;
