import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Newsletter extends Component {
    render() {
		let publicUrl = process.env.PUBLIC_URL+'/'

    return   <section className="fun-fact-area text-center pd-top-100" id="newsletter" style={{background: "var(--takeyourway-color)"}}>

			<div className="container">
			    <div className="row justify-content-center">
							<div className="col-lg-8">
								<div className="gran-canaria-newsletter">
									<div className="section-title text-center">
										<h2 style={{ color: "#fff" }}>Newsletter o Gran Canarii</h2>
										<p style={{ color: "#fff" }}>Dostaniesz paczkę tapet z Gran Canarii na dobry początek.</p>
									</div>
									<form className="subscription-form mailchimp form-inline" role="form">
										<div className="form-group text-center subscribe-input-group">
											<input type="text"
														 name="FNAME"
														 id="subscriber-name"
														 placeholder="Imię"
														 className="form-control input-box"
														 style={{ margin: "3px auto" }}
											/>
											<input type="email"
														 name="EMAIL"
														 id="subscriber-email"
														 placeholder="Email"
														 className="form-control input-box"
														 style={{ margin: "3px auto" }}
											/>
										</div>
										<input type="hidden" name="tags" value="12742981" />
										<a id="subscribe-button" className="button-gc button-gran-canaria-newsletter">
											<i className="fa fa-heart"></i>&nbsp; Kocham Kanary, dopisz mnie!
										</a>
										<h4 className="subscription-success"></h4>
										<h4 className="subscription-error"></h4>
									</form>
								</div>
							</div>
			    </div>
			  </div>      
			</section>
		}
}

export default Newsletter;
