import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer_v1 extends Component {

	componentDidMount() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";

		document.body.appendChild(minscript);
	}

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		let imgattr = "Footer logo"

		return (
			<footer className="footer-area pd-top-40">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="footer-widget widget widget_contact" style={{ textAlign: "center" }}>
								<h4 style={{ color: "var(--white)", marginTop: "20px" }}>Znajdziesz nas w mediach społecznościowych</h4>
								<ul className="social-area">
									<li><a href="https://instagram.com/takeyourwaypl/"><i className="fab fa-instagram" /></a></li>
									<li><a href="https://facebook.com/takeyourwaypl/"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="https://www.youtube.com/@takeyourway3907/"><i className="fab fa-youtube" /></a></li>
									<li><a href="https://www.tiktok.com/@takeyourwaypl/"><i className="fab fa-tiktok" /></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/*Footer bottom*/}
				<div className="container">
					<div className="copyright-area">
						<div className="row">
							<div className="col-lg-6 align-self-center">
								<p>©2023 <a href="https://takeyourway.pl">Takeyourway</a> with 🤍 to travel</p>
							</div>
							<div className="col-lg-6 text-lg-right">
								<ul>
									<li>
										<a href="https://takeyourway.pl/polityka-prywatnosci/">Polityka prywatności</a>
									</li>
									<li>
										<a href="https://takeyourway.pl/regulamin/">Regulamin</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>


		)
	}
}


export default Footer_v1