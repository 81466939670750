import React, { Component } from 'react';

class Socials extends Component {
	render() {
    return (
			<section className="fun-fact-area text-center pd-top-70" id="grupy" style={{background: "var(--takeyourway-color)"}}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<p className="social-header-icon">
								<i className="fas fa-users"></i>
							</p>
						</div>
						<div className="col-md-6 mt-5">
							<h4 style={{ color: "#fff" }}>Grupa na Facebooku</h4>
							<ul className="social-area social-area-groups">
								<li><a href="https://www.facebook.com/groups/grancanariawpigulce"><i className="fab fa-facebook-f" /></a></li>
							</ul>
						</div>
						<div className="col-md-6 mt-5">
							<h4 style={{ color: "#fff" }}>Kanał nadawczy na IG</h4>
							<ul className="social-area social-area-groups">
								<li><a href="https://ig.me/j/Aba-nCnxuH9BgEUq/"><i className="fab fa-instagram" /></a></li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Socials;
