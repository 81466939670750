import React, { Component } from 'react';

class Testimonial extends Component {

	render() {
		let publicUrl = process.env.PUBLIC_URL+'/'

		const lostItalianos = (
			<div className="item">
				<div className="testimonial-wrap">
					<div className="icon">
						<i className="fa fa-quote-left" style={{ fontSize: "50px", color: "var(--takeyourway-color)" }} />
					</div>
					<p>Nie wiem czy jest ktoś znający wyspę lepiej niż Ania i Grzesiek, ale wiem, że prawdopobnie nikt nie kocha jej bardziej. A powszechnie wiadomo, że z miłości powstają najlepsze rzeczy! Zarówno Plażownik jak i Planownik są nie tylko estetyczne, spójne i przejrzyste, ale przede wszystkim napakowane informacjami! Ciężko wyobrazić mi sobie lepsze narzędzia do zwiedzania Gran Canarii. ❤️</p>
					<div className="thumb">
						<img src={publicUrl+"assets/img/testimonial/lostitalianos.jpg"} width="60" height="60" alt="img" />
					</div>
					<h5 className="name">
						<a href="https://www.instagram.com/lostitalianos/">
							@lostitalianos
						</a>
					</h5>
					<p className="designation">
						Diana i Marcin
					</p>
				</div>
			</div>
		);

		const coupleAway = (
			<div className="item">
				<div className="testimonial-wrap">
					<div className="icon">
						<i className="fa fa-quote-left" style={{ fontSize: "50px", color: "var(--takeyourway-color)" }} />
					</div>
					<p>Plany podróży i plażownik to prawdziwa skarbnica wiedzy o Gran Canarii. Wszystko wyłożone na tacy, bardzo dobrze opisane, opatrzone przydatnymi grafikami i pięknymi zdjęciami. Dopełnieniem jest aplikacja, która w kilku krokach pomaga wybrać idealną plażę w zależności od preferencji. Czegoś takiego jeszcze nie było 😱 Z całego serca polecamy każdemu kto wybiera się na Gran Canarię 🩷</p>
					<div className="thumb">
						<img src={publicUrl+"assets/img/testimonial/coupleaway.jpg"} width="60" height="60" alt="img" />
					</div>
					<h5 className="name">
						<a href="https://www.instagram.com/couple.away/">
							@couple.away
						</a>
					</h5>
					<p className="designation">
						Justyna i Paweł
					</p>
				</div>
			</div>
		);

		const aleksandraPakula = (
			<div className="item">
				<div className="testimonial-wrap">
					<div className="icon">
						<i className="fa fa-quote-left" style={{ fontSize: "50px", color: "var(--takeyourway-color)" }} />
					</div>
					<p>Gran Canaria nigdy nie była na liście moich podróżniczych celów, ale po przeczytaniu Planownika znienacka znalazła się w pierwszej trójce! Anna i Grzegorz zrobili za odwiedzających wszystko to, co zabiera najwięcej czasu i powoduje frustracje lub odsuwanie wyprawy w nieskończoność, czyli zaplanowali pobyt, w którym każdy znajdzie coś dla siebie. Fantastyczna jakość merytoryczna, niesamowite zdjęcia i przejrzysty skład, czyli profesjonalizm w każdym calu. Gran Canario – przez, a właściwie dzięki Takeyourway - przybywam!</p>
					<div className="thumb">
						<img src={publicUrl+"assets/img/testimonial/aleksandrapakula.jpg"} width="60" height="60" alt="img" />
					</div>
					<h5 className="name">
						<a href="https://www.instagram.com/aleksandrapakulapl/">
							@aleksandrapakulapl
						</a>
					</h5>
					<p className="designation">
						Aleksandra
					</p>
				</div>
			</div>
		);

		const rozgotovani = (
			<div className="item">
				<div className="testimonial-wrap">
					<div className="icon">
						<i className="fa fa-quote-left" style={{ fontSize: "50px", color: "var(--takeyourway-color)" }} />
					</div>
					<p>Oba przewodniki zaszczepiły w nas ciekawość do GC! Świetne źródło informacji i kompleksowa baza wiedzy napisana przez osoby, które są zakochane po uszy w wyspie i we wszystkim co ona oferuje. W dodatku... potrafią tą miłością zarazić! Do małej wyspy kryjącej w sobie ogrom piękna.</p>
					<div className="thumb">
						<img src={publicUrl+"assets/img/testimonial/rozgotovani.jpg"} width="60" height="60" alt="img" />
					</div>
					<h5 className="name">
						<a href="https://www.instagram.com/rozgotovani/">@rozgotovani</a>
					</h5>
					<p className="designation">
						Karolina i Krzysiek
					</p>
				</div>
			</div>
		);

		const ewelinagac = (
			<div className="item">
				<div className="testimonial-wrap">
					<div className="icon">
						<i className="fa fa-quote-left" style={{ fontSize: "50px", color: "var(--takeyourway-color)" }} />
					</div>
					<p>Gran Canaria to wyspa dosłownie dla każdego. Tu odkryjemy urok historycznych atrakcji, znajdą się miejsca dla plażowiczów, ale także dla miłośników przyrody i kultury. Świetnych punktów jest mnóstwo, dlatego trudnej samemu zorganizować zwiedzanie, jeśli na urlop ma się zaledwie tydzień.<br /><br />Z planownikiem Ani i Grześka nie musimy się o nic martwic, bo nie dość, że plan już jest, to wszystkie cudowne punkty mamy dodane na interaktywną mapę. Szczerze polecam, 10/10 ♥️</p>
					<div className="thumb">
						<img src={publicUrl+"assets/img/testimonial/ewelinagac.jpg"} width="60" height="60" alt="img" />
					</div>
					<h5 className="name">
						<a href="https://www.instagram.com/ewelina.gac">@ewelina.gac
						</a>
					</h5>
					<p className="designation">
						Ewelina
					</p>
				</div>
			</div>
		);

		const shuffleArray = (array) => {
			for (let i = array.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[array[i], array[j]] = [array[j], array[i]];
			}
			return array;
		};

		const getTestimonials = () => {
			return shuffleArray([
				lostItalianos,
				coupleAway,
				aleksandraPakula,
				rozgotovani,
				ewelinagac,
			]);
		}

    return (
			<section className="testimonial-area text-center pd-top-90 pd-bottom-70" id="opinie">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-8">
			        <div className="section-title text-center">
			          <h2>Opinie</h2>
			          {/*<p></p>*/}
			        </div>
			      </div>
			    </div>
			    <div className="row justify-content-center">
			      <div className="col-12">
			        <div className="testimonial-slider owl-carousel owl-theme">
								{getTestimonials()}
			        </div>
			      </div>
			    </div>
			  </div>
			</section>
		);
	}
}

export default Testimonial